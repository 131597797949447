.story {
  background: linear-gradient(135deg, #1e1e2f, #31315a);
  color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: normal;  overflow: hidden;
  max-width: 1260px;
  margin-left: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shareButton {
  background: linear-gradient(90deg, #f39c12, #e74c3c);
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s ease;
}

.shareButton:hover {
  background: linear-gradient(90deg, #e67e22, #c0392b);
}

.timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  padding: 10px;
  overflow-x: auto;
}

.milestone {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 20px;
}

.milestoneNode {
  width: 50px;
  height: 50px;
  background: linear-gradient(90deg, #3498db, #8e44ad);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.milestoneLabel {
  margin-top: 10px;
  color: #bdc3c7;
}

.achievementsSection {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.achievements, .challenges {
  width: 45%;
}

.cards {
  display: flex;
  gap: 15px;
}

.card {
  background: linear-gradient(135deg, #2c3e50, #34495e);
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  perspective: 1000px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.cardFront, .cardBack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
}

.cardFront {
  transform: rotateY(0deg);
}

.cardBack {
  transform: rotateY(180deg);
  position: absolute;
}
