.story {
  padding: 20px;
  background: linear-gradient(135deg, rgba(240, 240, 255, 0.8), rgba(255, 255, 255, 0.9));
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 1260px;
  margin-left: 40px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;}

.title {
  text-align: center;
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 30px;
}

.timeline {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.milestone {
  display: flex;
  align-items: center;
  gap: 20px;
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.milestone:nth-child(even) {
  flex-direction: row-reverse;
}

.icon {
  width: 60px;
  height: 60px;
  background: #3498db;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 5px solid rgba(52, 152, 219, 0.2);
  border-radius: 50%;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.glow {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(52, 152, 219, 0.8);
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.content {
  flex: 1;
}

.content h3 {
  font-size: 1.2rem;
  color: #34495e;
}

.content p {
  font-size: 1rem;
  color: #7f8c8d;
}

.date {
  font-size: 0.9rem;
  color: #bdc3c7;
  margin-top: 5px;
}
