.dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  padding: 30px;
  max-width: 1300px;
  margin-left: 40px;
  background: linear-gradient(135deg, #eef2f3, #ffffff);
  border-radius: 20px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}

.widget {
  background: linear-gradient(135deg, #ffffff, #f5f5f5);
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.widgetHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.widgetHeader h3 {
  font-size: 1.4rem;
  color: #34495e;
  margin: 0;
}

.widgetHeader button {
  background: none;
  border: none;
  font-size: 1.4rem;
  color: #95a5a6;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.widgetHeader button:hover {
  color: #34495e;
  transform: scale(1.2);
}

.skillChart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skillInfo {
  text-align: center;
  margin-top: 10px;
}

.skillInfo strong {
  font-size: 1.8rem;
  color: #2ecc71;
}

.skillComparison p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #7f8c8d;
}

.goalList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.goalItem {
  margin-bottom: 15px;
}

.goalItem p {
  margin: 0 0 5px 0;
  font-size: 1rem;
  color: #2c3e50;
}

.nextSteps li,
.upcomingSessions li,
.leaderboard li {
  margin: 10px 0;
  font-size: 0.9rem;
  color: #34495e;
}

.feedbackList li {
  margin-bottom: 15px;
  font-size: 0.9rem;
  color: #34495e;
}

.streaks {
  font-size: 1rem;
  color: #e74c3c;
  text-align: center;
  background: linear-gradient(135deg, #ffcccc, #ffe6e6);
  border-radius: 10px;
  padding: 10px;
}

.minimize-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  border: none;
  border-radius: 50%;
  background: rgba(220, 220, 220, 0.7);
  font-size: 1rem;
  color: #7f8c8d;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
}

.minimize-button:hover {
  background: rgba(220, 220, 220, 0.9);
  transform: scale(1.2);
}

