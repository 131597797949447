.feedbacks {
  padding: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(240, 240, 255, 0.7));
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;  
  max-width: 1400px;
  margin-left: 60px;
}

/* Section Headers */
.heading {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 10px;
  text-align: center;
}

.description {
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 20px;
  text-align: center;
}

/* Topic Section */
.topicSection {
  margin-bottom: 30px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.topicHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.topicName {
  font-size: 1.4rem;
  font-weight: bold;
  color: #34495e;
}

.rating {
  font-size: 1.1rem;
  color: #7f8c8d;
}

.rating span {
  color: #6c5ce7;
  font-weight: bold;
}

/* Milestone Tracker */
.promotionTracker {
  margin-bottom: 20px;
}

.progressBar {
  background: #f0f0f0;
  border-radius: 8px;
  height: 10px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.progress {
  background: linear-gradient(135deg, #6c5ce7, #00cec9);
  height: 100%;
  border-radius: 8px 0 0 8px;
  transition: width 0.3s ease-in-out;
}

.milestoneText {
  font-size: 0.9rem;
  color: #7f8c8d;
  text-align: right;
  margin-top: 5px;
}

/* Feedback List */
.feedbackList {
  margin-top: 20px;
}

.feedbackCard {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.feedbackCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.feedbackDetails {
  margin-bottom: 10px;
}

.date {
  font-size: 0.9rem;
  color: #7f8c8d;
}

.comment {
  font-size: 1rem;
  color: #34495e;
}

.feedbackCategory {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #6c5ce7;
  color: white;
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 8px;
  text-transform: uppercase;
}

/* Feedback Categories */
.positive {
  border-left: 5px solid #27ae60;
}

.neutral {
  border-left: 5px solid #f1c40f;
}

.negative {
  border-left: 5px solid #e74c3c;
}
