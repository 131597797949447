/* Teach Platform Container */
.teachPlatform {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  font-family: "Roboto", Arial, sans-serif;
  background-color: #f8f9fc;
  color: #333;
}

/* Navbar at the top */
.layout {
  display: flex;
  flex: 1; /* Fills remaining space after Navbar */
  overflow: hidden; /* Prevents scrolling issues */
}

/* Sidebar Styles */
.sidebar {
  width: 250px; /* Sidebar fixed width */
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Allow vertical scrolling for sidebar */
  transition: transform 0.3s ease-in-out;
}

.sidebar.collapsed {
  width: 80px; /* Collapse sidebar for compact mode */
}

.sidebar li {
  list-style: none;
  margin: 15px 0;
}

.sidebar a {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 1rem;
  display: block;
  padding: 10px 15px;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.sidebar a:hover {
  background-color: #34495e;
}

/* Main Content Styles */
.mainContent {
  flex: 1; /* Fill available space */
  overflow-y: auto; /* Enable scrolling for main content */
  padding: 20px;
  background-color: #ffffff;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
}

.mainContent h1,
.mainContent h2 {
  margin-bottom: 1rem;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 600;
  color: #2c3e50;
}

.mainContent p {
  font-size: 1rem;
  line-height: 1.6;
  color: #7f8c8d;
}

/* Responsiveness */
@media (max-width: 768px) {
  .layout {
    flex-direction: column; /* Stack Sidebar and Content for small screens */
  }

  .sidebar {
    width: 100%; /* Sidebar takes full width */
    height: auto;
    box-shadow: none;
  }

  .mainContent {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .sidebar a {
    font-size: 0.9rem;
  }

  .mainContent h1,
  .mainContent h2 {
    font-size: 1.5rem;
  }

  .mainContent p {
    font-size: 0.95rem;
  }
}
