.notFound {
    text-align: center;
    padding: 50px;
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-size: 2.5rem;
    color: #ff6f61; /* Warm Red */
  }
  
  .description {
    color: #555;
    margin: 20px 0;
  }
  
  .link {
    font-size: 1.2rem;
    color: #007acc;
    text-decoration: none;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  