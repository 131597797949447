.notifications {
  padding: 20px;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: auto;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.badge {
  background: #e74c3c;
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 12px;
}

.filterSection {
  margin: 15px 0;
  display: flex;
  gap: 10px;
}

.filterSection button {
  padding: 8px 16px;
  background: #ecf0f1;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

.filterSection button:hover {
  background: #dcdde1;
}

.notificationList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.cardContent {
  max-width: 70%;
}

.timestamp {
  font-size: 0.8rem;
  color: #7f8c8d;
}

.cardActions button {
  margin: 10px;
  padding: 6px 12px;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background 0.3s;
}

.cardActions button:hover {
  background: #2980b9;
}

.read {
  opacity: 0.6;
}
