/* General Sidebar Styles */
.sidebar {
  width: 60px;
  height: 100vh;
  background: rgba(44, 62, 80, 0.6); /* Glassmorphism effect */
  backdrop-filter: blur(12px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  gap: 20px;
  transition: width 0.4s ease, backdrop-filter 0.4s ease;
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: fixed;
  z-index: 1000;
  border-radius: 8px;
}

.sidebar.expanded {
  width: 240px;
  padding: 15px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.logoIcon {
  font-size: 2rem;
  color: #ecf0f1;
}

.logoText {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ecf0f1;
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.sidebar.expanded .logoText {
  opacity: 1;
  transform: translateX(0);
}

/* Menu Item Styles */
.menuItem {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px 20px;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.3s ease, transform 0.3s ease;
  position: relative;
  overflow: hidden;
  margin-left: 30px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}

.menuItem:hover {
  background: linear-gradient(135deg, #3498db, #9b59b6);
  transform: scale(1.05);
}

.icon {
  font-size: 1.8rem;
  color: #ecf0f1;
  transition: transform 0.3s ease, color 0.3s ease;
}

.label {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.sidebar.expanded .label {
  opacity: 1;
  transform: translateX(0);
}

/* Hover Effects */
.menuItem:hover .icon {
  color: #ffffff;
  transform: scale(1.2);
}

/* Active Indicator */
.menuItem::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 4px;
  left: 0;
  top: 0;
  background: linear-gradient(135deg, #3498db, #9b59b6);
  opacity: 0;
  transform: scaleY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.menuItem:hover::before {
  opacity: 1;
  transform: scaleY(1);
}
