.freePlatformContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: linear-gradient(135deg, #f0f0f0, #ffffff);
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
  text-align: center;
}

.header {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.bookingSection,
.loadingSection,
.confirmationSection {
  width: 100%;
}

.instruction {
  font-size: 1.2rem;
  color: #34495e;
  margin-bottom: 20px;
}

.scheduleButton {
  background: linear-gradient(135deg, #4caf50, #2ecc71);
  color: white;
  padding: 12px 25px;
  border-radius: 10px;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.scheduleButton:hover {
  background: linear-gradient(135deg, #3e8e41, #1abc9c);
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 255, 0, 0.3);
}

.loadingSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loadingText {
  font-size: 1.2rem;
  color: #2c3e50;
  margin-top: 15px;
  animation: fadeIn 1s ease infinite alternate;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.confirmationSection {
  background: linear-gradient(135deg, #e8f8f5, #d1f2eb);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 255, 0, 0.2);
}

.confirmationMessage {
  font-size: 1.5rem;
  color: #2ecc71;
  margin-bottom: 10px;
}

.nextStep {
  font-size: 1.1rem;
  color: #34495e;
  margin-top: 5px;
}
