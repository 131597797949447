/* Dashboard container */
.dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px; /* Larger gaps for breathing space */
  max-width: 1240px;
  margin-left: 40px;
  padding: 20px;
  background: linear-gradient(135deg, #eef2f3, #ffffff); /* Soft gradient background */
  border-radius: 16px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1); /* Subtle outer shadow */
}

/* Widget container */
.widget {
  background: #ffffff; /* Clean white for clarity */
  border-radius: 12px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1); /* Subtle border for separation */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Slight 3D card effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
  overflow: hidden; /* Ensure content doesn't overflow */
  cursor: pointer; /* Visual indicator for interactivity */
}

/* Widget heading */
.widget h3 {
  font-size: 1.2rem; /* Larger heading size */
  font-weight: bold;
  margin-bottom: 10px;
  color: #2c3e50; /* Darker heading for clarity */
  text-align: center;
  background: linear-gradient(90deg, #3498db, #9b59b6); /* Vibrant gradient text */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Gradient on text */
  font-family: "Zen Dots", sans-serif;

}

/* Widget content */
.widget p,
.widget li {
  font-size: 1rem;
  font-weight: 400;
  color: #7f8c8d; /* Slightly darker text for better readability */
  text-align: center;
  margin: 10px 0;
  font-family: "Kanit", sans-serif;

}

/* List inside widgets */
.widget ul {
  list-style: none;
  padding: 0;
}

.widget ul li {
  background: rgba(52, 152, 219, 0.1); /* Subtle highlight for list items */
  margin: 6px 0;
  padding: 10px;
  border-radius: 8px;
  font-size: 0.9rem;
  color: #34495e;
  transition: background 0.3s ease;
}

.widget ul li:hover {
  background: rgba(52, 152, 219, 0.2); /* Highlight on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard {
    grid-template-columns: 1fr; /* Single-column layout for smaller screens */
  }

  .widget {
    padding: 15px;
  }

  .widget h3 {
    font-size: 1.6rem;
  }

  .widget p,
  .widget li {
    font-size: 0.9rem;
  }
}
