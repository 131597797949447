.learning {
  padding: 20px;
  background: linear-gradient(135deg, #f0f4ff, #dfe8f7);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 1400px;
  margin: 20px auto;
  transition: all 0.3s ease-in-out;
  margin-left: 40px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1000;
  padding: 40px;
  overflow-y: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.headerActions {
  display: flex;
  gap: 10px;
}

.actionButton {
  background: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background 0.3s ease;
}

.actionButton:hover {
  background: #2980b9;
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.sessionDetails,
.resources,
.test {
  flex: 1 1 calc(33% - 20px);
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.sessionDetails:hover,
.resources:hover,
.test:hover {
  transform: translateY(-5px);
}

.timer {
  margin-top: 10px;
  text-align: center;
  font-size: 1rem;
  color: #34495e;
}

.testButton {
  background: #e67e22;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.testButton:hover {
  background: #d35400;
}

.testButton:disabled {
  background: #bdc3c7;
  cursor: not-allowed;
}

.resources ul {
  list-style: none;
  padding: 0;
}

.resources li {
  margin-bottom: 10px;
}

.resources a {
  text-decoration: none;
  color: #3498db;
  font-weight: 600;
  transition: color 0.3s ease;
}

.resources a:hover {
  color: #2980b9;
}
