.notifications {
  padding: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(240, 240, 255, 0.8));
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;  max-width: 800px;
  margin: auto;
}

/* Header */
.heading {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 20px;
  text-align: center;
}

/* Notification List */
.notificationList {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.cardHeader h3 {
  font-size: 1.2rem;
  color: #34495e;
}

.time {
  font-size: 0.8rem;
  color: #7f8c8d;
}

.message {
  font-size: 1rem;
  color: #2c3e50;
}

/* Notification Categories */
.payment {
  border-left: 5px solid #3498db;
}

.classreminder {
  border-left: 5px solid #2ecc71;
}

.achievement {
  border-left: 5px solid #e67e22;
}

.challenge {
  border-left: 5px solid #9b59b6;
}

.performance {
  border-left: 5px solid #e74c3c;
}
