.goalManagement {
  padding: 20px;
  background: linear-gradient(135deg, #ffffff, #f2f6fc);
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;  color: #2c3e50;
  border-radius: 12px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  max-width: 1260px;
  margin-left: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h2 {
  font-size: 2.2rem;
  font-weight: 700;
  color: #34495e;
}

.header button {
  padding: 12px 25px;
  background: linear-gradient(135deg, #6c5ce7, #00cec9);
  color: white;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.header button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.goalTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.goalTable th,
.goalTable td {
  text-align: left;
  padding: 12px 20px;
  border-bottom: 1px solid #e0e0e0;
}

.goalTable th {
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
  font-weight: 700;
  color: #34495e;
}

.goalTable tr:nth-child(even) {
  background: #fbfbfb;
}

.goalTable tr:hover {
  background: #f5faff;
  transition: background 0.3s ease;
}

.goalTable button {
  margin-right: 8px;
  padding: 8px 15px;
  font-size: 0.95rem;
  font-weight: 500;
  background: linear-gradient(135deg, #3498db, #9b59b6);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  transition: background 0.3s ease, transform 0.3s ease;
}

.goalTable button:hover {
  background: linear-gradient(135deg, #9b59b6, #3498db);
  transform: scale(1.1);
}

.analytics {
  margin-top: 30px;
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
}

.analytics h3 {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.gamification {
  margin-top: 30px;
  padding: 20px;
  background: linear-gradient(135deg, #ffffff, #f0f4ff);
  border-radius: 10px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.08);
}

.gamification h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #34495e;
}

.gamification p {
  font-size: 1.1rem;
  margin: 8px 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.4s ease;
}

.modalContent {
  background: linear-gradient(135deg, #ffffff, #eef3fc);
  width: 80%;
  max-width: 900px;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  animation: slideUp 0.5s ease;
}

.modalContent h3 {
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 25px;
}

.tracker {
  display: flex;
}

.sidebar {
  width: 25%;
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.sidebar .tab {
  padding: 12px 15px;
  margin-bottom: 10px;
  background: linear-gradient(135deg, #bdc3c7, #ecf0f1);
  color: #34495e;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: transform 0.3s ease, background 0.3s ease;
}

.sidebar .tab:hover,
.sidebar .activeTab {
  background: linear-gradient(135deg, #3498db, #9b59b6);
  color: white;
  transform: scale(1.05);
}

.main {
  flex-grow: 1;
  margin-left: 20px;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.05);
}

.bubble {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 15px;
  background: linear-gradient(135deg, #dfe6e9, #ffffff);
  color: #2c3e50;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.bubble:hover {
  transform: scale(1.2);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.bubble.selfRead {
  background: linear-gradient(135deg, #f39c12, #e67e22);
  color: white;
}

.bubble.completed {
  background: linear-gradient(135deg, #2ecc71, #27ae60);
  color: white;
}

.stats {
  margin-top: 30px;
  padding: 15px;
  background: linear-gradient(135deg, #ffffff, #f9f9f9);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
}

.closeButton {
  margin-top: 20px;
  padding: 12px 25px;
  background: linear-gradient(135deg, #e74c3c, #c0392b);
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.closeButton:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
