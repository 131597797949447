.faqs {
  background: #f9fafb;
  padding: 20px;
  border-radius: 12px;
  max-width: 800px;
  margin: 20px auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: normal;}

.faqs h2 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 20px;
  text-align: center;
}

.searchBar {
  margin-bottom: 20px;
  text-align: center;
}

.searchBar input {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
}

.noResults {
  text-align: center;
  font-size: 1.2rem;
  color: #e74c3c;
}

.category {
  margin-bottom: 20px;
}

.category h3 {
  font-size: 1.5rem;
  color: #34495e;
  margin-bottom: 10px;
  border-bottom: 2px solid #ecf0f1;
  padding-bottom: 5px;
}

.question {
  margin: 10px 0;
}

.questionHeader {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
  background: #ecf0f1;
  border-radius: 8px;
  transition: background 0.3s ease;
}

.questionHeader:hover {
  background: #e3e7ed;
}

.answer {
  padding: 10px;
  background: #f5f7fa;
  border-left: 3px solid #3498db;
  margin: 5px 0;
  border-radius: 8px;
  font-size: 0.95rem;
  color: #2c3e50;
}

.contactSupport {
  margin-top: 30px;
  text-align: center;
}

.contactSupport a {
  color: #3498db;
  font-weight: bold;
  text-decoration: none;
}

.contactSupport a:hover {
  text-decoration: underline;
}
