.catalogueLayer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: #f8f9fa; /* Softer background for better contrast */
  min-height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
}

.backButton {
  padding: 10px 15px;
  font-size: 1rem;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.backButton:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.card {
  background: #ffffff; /* Simplified solid background */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Softer shadow */
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card:hover {
  transform: translateY(-5px); /* Gentle lift on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.cardFront,
.cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  text-align: center;
  transition: transform 0.6s ease;
}

.cardFront {
  background: linear-gradient(120deg, #007bff, #6610f2);
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
}

.cardBack {
  background: #f8f9fa;
  color: #2c3e50;
  transform: rotateY(180deg);
}

.card:hover .cardFront {
  transform: rotateY(180deg);
}

.card:hover .cardBack {
  transform: rotateY(360deg);
}

.enterButton {
  margin-top: 10px;
  padding: 8px 12px;
  font-size: 0.9rem;
  background-color: #28a745;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.enterButton:hover {
  background-color: #218838;
  transform: translateY(-2px);
}
