.resources {
  padding: 20px;
  background: linear-gradient(135deg, #f5f7fa, #ffffff);
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;  max-width: 1280px;
  margin-left: 40px;
}

.heading {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.searchBar {
  flex: 2;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
}

.filterDropdown {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
}

.resourceList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.resourceCard {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.resourceCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.thumbnail {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.cardDetails {
  text-align: center;
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #34495e;
}

.description {
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 10px;
}

.type {
  font-size: 0.9rem;
  color: #aaa;
}

.restrictedMessage {
  color: #e74c3c;
  font-size: 0.9rem;
  font-weight: bold;
}

.viewButton {
  padding: 8px 16px;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
}

.viewButton:hover {
  background: #2980b9;
}

.restricted {
  opacity: 0.5;
}
