.earningsAnalytics {
  padding: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(240, 240, 255, 0.9));
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: normal;  max-width: 800px;
  margin: auto;
}

/* Header */
.heading {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 20px;
}

/* Total Earnings */
.totalEarnings {
  text-align: center;
  margin-bottom: 30px;
}

.totalEarnings h3 {
  font-size: 1.2rem;
  color: #7f8c8d;
}

.totalEarnings .amount {
  font-size: 2.5rem;
  font-weight: bold;
  color: #3498db;
  margin-top: 5px;
}

/* Chart Section */
.chartSection {
  margin-bottom: 30px;
}

.chartSection h3 {
  font-size: 1.2rem;
  color: #34495e;
  margin-bottom: 15px;
  text-align: center;
}

/* Revenue Milestones */
.milestones {
  margin-top: 20px;
  text-align: center;
}

.milestones h3 {
  font-size: 1.2rem;
  color: #34495e;
  margin-bottom: 10px;
}

.milestones span {
  font-weight: bold;
  color: #2ecc71;
}

.progressBar {
  background: #f0f0f0;
  border-radius: 8px;
  height: 10px;
  width: 100%;
  margin: 10px auto;
  overflow: hidden;
  position: relative;
}

.progress {
  background: linear-gradient(135deg, #3498db, #2ecc71);
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.milestoneText {
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-top: 10px;
}
