.endeavourPlatform {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 1400px;
}

/* Layout with Sidebar and Main Content */
.layout {
  display: flex;
  flex: 1;
  height: calc(100vh - 60px); /* Adjust height based on Navbar height */
}

.mainContent {
  flex: 1;
  padding: 20px;
  background: #f9f9f9;
  overflow-y: auto;
}
