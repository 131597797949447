.competitions {
  padding: 25px;
  background: linear-gradient(to bottom, #1c1f25, #262a33);
  color: #ffffff;
  min-height: 100vh;
  max-width: 1400px;
  margin-left: 60px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}

.filters {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 20px;
}

.filters select {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 1rem;
}

.activeCompetitions,
.history {
  margin-top: 20px;
}

.competitionList {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.competitionCard {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.competitionCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.participateButton {
  margin-top: 10px;
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, transform 0.3s ease;
}

.participateButton:hover {
  background: linear-gradient(90deg, #e52e71, #ff8a00);
  transform: scale(1.1);
}
