/* LearnSidebar.module.css */
.sidebar {
  width: 50px; /* Collapsed width */
  height: 100vh;
  background: rgba(44, 62, 80, 0.6); /* Glassmorphism background */
  backdrop-filter: blur(12px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  transition: width 0.4s ease, backdrop-filter 0.4s ease;
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: fixed;
  z-index: 1000;
  border-radius: 8px;
  padding-top: 15px;
}

.sidebar.expanded {
  width: 300px; /* Expanded width */
  align-items: flex-start; /* Left-align content in expanded state */
  padding-left: 10px; /* Add space for left-aligned items */
  backdrop-filter: blur(15px);
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #ecf0f1;
  padding: 12px;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%; /* Full width for left alignment */
  margin-left: 25px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}

.menuItem:hover {
  background: linear-gradient(135deg, #3498db, #9b59b6);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Hover glow */
  transform: translateY(-3px); /* Lift effect */
}

.menuItem.active {
  background: rgba(255, 255, 255, 0.2);
  color: #4caf50;
}

.icon {
  font-size: 1.5rem;
  color: #ecf0f1;
  transition: transform 0.3s ease, color 0.3s ease;
}

.menuItem:hover .icon {
  color: #ffffff;
  transform: scale(1.2);
}

.label {
  font-size: 1rem;
  font-weight: 600;
  color: #ecf0f1;
  opacity: 0; /* Hidden by default in collapsed state */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.sidebar.expanded .label {
  opacity: 1;
  transform: translateX(0);
}

.sidebar .label {
  transform: translateX(-20px);
}

@media (max-width: 768px) {
  .sidebar {
    width: 40px;
  }

  .sidebar.expanded {
    width: 180px;
  }

  .menuItem {
    gap: 10px;
    padding: 8px;
  }

  .icon {
    font-size: 1.2rem;
  }

  .label {
    font-size: 0.85rem;
  }
}
