.layerProgress {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
  width: 100%;
}

.progressBar {
  position: relative;
  width: 90%;
  height: 12px;
  background: rgba(224, 224, 224, 0.5);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #4caf50, #3498db, #9b59b6);
  transition: width 0.6s ease-in-out;
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.7), 0 0 20px rgba(155, 89, 182, 0.7);
}

.steps {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 20px;
  position: relative;
}

.step {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #e0e0e0;
  color: #7f8c8d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background 0.6s ease, transform 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.step:hover {
  transform: scale(1.1);
}

.step.completed {
  background: linear-gradient(135deg, #4caf50, #3498db);
  color: white;
  box-shadow: 0 0 12px rgba(52, 152, 219, 0.8);
}

.stepNumber {
  z-index: 1;
}

.tooltip {
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  background: #2c3e50;
  color: white;
  font-size: 0.8rem;
  padding: 5px 10px;
  border-radius: 6px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.step:hover .tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-5px);
}
