/* Container styles */
.sessionManagement {
  padding: 30px;
  font-family: "Roboto", Arial, sans-serif;
  color: #333;
  background-color: #f4f6f8;
  min-height: 100vh;
  box-sizing: border-box;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #0077cc;
  text-align: center;
}

.description {
  font-size: 1.1rem;
  margin-bottom: 25px;
  color: #666;
  text-align: center;
}

/* Schedule grid */
.schedule {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.day {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.day:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.dayHeading {
  font-size: 1.3rem;
  font-weight: 600;
  color: #0056b3;
  text-align: center;
  margin-bottom: 10px;
}

/* Time slot styles */
.timeSlots {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 10px;
}

.timeSlot {
  background-color: #e9ecef;
  color: #333;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  font-size: 0.9rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.timeSlot.available {
  background-color: #28a745;
  color: #fff;
  font-weight: 500;
}

.timeSlot:hover {
  background-color: #17a2b8;
  color: #fff;
  transform: scale(1.05);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

/* Action buttons */
.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
}

.saveButton,
.resetButton,
.applyButton {
  padding: 12px 25px;
  font-size: 1.1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.saveButton {
  background-color: #007bff;
  color: white;
}

.saveButton:hover {
  background-color: #0056b3;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.resetButton {
  background-color: #dc3545;
  color: white;
}

.resetButton:hover {
  background-color: #a71d2a;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.applyButton {
  background-color: #ffc107;
  color: #333;
}

.applyButton:hover {
  background-color: #e0a800;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive styles */
@media (max-width: 768px) {
  .heading {
    font-size: 1.8rem;
  }

  .description {
    font-size: 1rem;
  }

  .day {
    padding: 10px;
  }

  .dayHeading {
    font-size: 1.2rem;
  }

  .timeSlots {
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  }

  .timeSlot {
    font-size: 0.85rem;
    padding: 8px;
  }

  .saveButton,
  .resetButton,
  .applyButton {
    padding: 10px 20px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.6rem;
  }

  .description {
    font-size: 0.95rem;
  }

  .timeSlots {
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  }

  .timeSlot {
    font-size: 0.8rem;
    padding: 6px;
  }

  .saveButton,
  .resetButton,
  .applyButton {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
