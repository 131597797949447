.sessionBooking {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}
.platformSelection button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  background: #3498db;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.platformSelection button:hover {
  transform: translateY(-3px);
}
