.testPlatform {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(240, 240, 255, 0.9));
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  font-size: 1.8rem;
  color: #34495e;
}

.backButton {
  display: block;
  margin: 10px 0;
  padding: 10px 20px;
  background: #e74c3c;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.backButton:hover {
  background: #c0392b;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.card {
  background: white;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.topicDetails {
  margin-top: 20px;
  text-align: center;
}

.startTestButton {
  margin-top: 10px;
  padding: 10px 20px;
  background: #2ecc71;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.startTestButton:hover {
  background: #27ae60;
}
