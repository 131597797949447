.sidebar {
  width: 50px; /* Collapsed width */
  height: 100vh;
  background: rgba(44, 62, 80, 0.6); /* Glassmorphism background */
  backdrop-filter: blur(12px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  transition: width 0.4s ease, backdrop-filter 0.4s ease;
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: fixed; /* Fixed position for sticky sidebar */
  z-index: 1000;
  border-radius: 8px;
  padding-top: 15px;
}

.sidebar.expanded {
  width: 300px; /* Expanded width */
  align-items: flex-start; /* Left-align content in expanded state */
  padding-left: 10px; /* Add space for left-aligned items */
  backdrop-filter: blur(15px);
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #ecf0f1;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%; /* Full width for left alignment */
  margin-left: 25px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}

.menuItem:hover {
  background: linear-gradient(135deg, #3498db, #9b59b6);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Hover glow */
  transform: translateY(-3px); /* Lift effect */
  margin-left: 0px !important;
}

.icon {
  font-size: 1.3rem;
  color: #ecf0f1;
  transition: transform 0.3s ease, color 0.3s ease;
}

.menuItem:hover .icon {
  color: #ffffff; /* Brighten the icon on hover */
  transform: scale(1.2); /* Slight zoom on hover */
}

.label {
  font-size: 1rem;
  font-weight: 600;
  color: #ecf0f1;
  opacity: 0; /* Hidden by default in collapsed state */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.sidebar.expanded .label {
  opacity: 1;
  transform: translateX(0); /* Slide in on expand */
}

.sidebar .label {
  transform: translateX(-20px); /* Hide label when collapsed */
}

