/* Main Profile Container */
.profile {
    padding: 20px;
    background: #f4f7fc; /* Subtle neutral background */
    border-radius: 16px;
    max-width: 1400px;
    margin: 20px auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    font-family: "Kanit", sans-serif;
}
  
  /* Header Section */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(135deg, #4a90e2, #9013fe);
    color: white;
    border-radius: 12px;
    padding: 15px 20px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    margin: 20px;
  }
  
  .profilePicture {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .profilePicture img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid white;
    object-fit: cover;
  }
  
  .profileDetails {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .name {
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .completionBar {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .progress {
    flex: 1;
    height: 6px;
    background: linear-gradient(90deg, #4caf50, #ffcc00);
    border-radius: 10px;
    transition: width 0.4s ease;
  }
  
  .completionText {
    font-size: 0.85rem;
    color: #ecf0f1;
    font-weight: 500;
  }
  
  /* Section Layout */
  .sections {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
  }
  
  /* Section Card */
  .sectionCard {
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .sectionCard:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
  
  .sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sectionHeader h3 {
    font-size: 1rem;
    font-weight: 600;
    color: #34495e;
    margin: 0;
  }
  
  .sectionHeader button {
    background: #4a90e2;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .sectionHeader button:hover {
    background: #357ab7;
  }
  
  /* Section Content */
  .sectionContent {
    font-size: 0.9rem;
    line-height: 1.5;
    color: #5d6d7e;
  }
  
  .sectionContent .list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .sectionContent .list span {
    background: #eef3f8;
    padding: 5px 10px;
    border-radius: 6px;
    font-size: 0.85rem;
    color: #4a90e2;
    border: 1px solid #4a90e2;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .sectionContent .list span:hover {
    background: #4a90e2;
    color: white;
  }
  
  /* Footer Actions */
  .footerActions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .footerActions button {
    padding: 8px 15px;
    font-size: 0.9rem;
    font-weight: 500;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .footerActions .saveButton {
    background: #27ae60;
    color: white;
  }
  
  .footerActions .saveButton:hover {
    background: #1e8449;
  }
  
  .footerActions .cancelButton {
    background: #e74c3c;
    color: white;
  }
  
  .footerActions .cancelButton:hover {
    background: #c0392b;
  }
  