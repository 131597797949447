.legalCompliance {
  padding: 20px;
  background: linear-gradient(to bottom, #2c3e50, #1a252f);
  color: #ecf0f1;
  min-height: 100vh;
  max-width: 1400px;
  margin-left: 60px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}

h2 {
  font-size: 2.5rem;
  text-align: center;
  color: #f1c40f;
  margin-bottom: 10px;
}

p {
  text-align: center;
  color: #bdc3c7;
  margin-bottom: 30px;
}

.documentsSection {
  margin-top: 20px;
}

.documentsTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.documentsTable th,
.documentsTable td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #34495e;
}

.documentsTable th {
  background: #34495e;
  color: #ecf0f1;
}

.pending {
  color: #f39c12;
}

.signed {
  color: #27ae60;
}

.reviewed {
  color: #3498db;
}

.signButton,
.reviewButton {
  background: #3498db;
  color: #ffffff;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin: 5px 15px;
}

.addButton {
  background: #3498db;
  color: #ffffff;
  border: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin: 5px 15px;
}

.signButton:hover,
.reviewButton:hover,
.addButton:hover {
  background: #2980b9;
}

.additionalFeatures {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}
