.projects {
  padding: 20px;
  background: linear-gradient(to bottom, #1c1f25, #262a33);
  color: #ffffff;
  min-height: 100vh;
  max-width: 1400px;
  margin-left: 60px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}


p {
  font-size: 1.2rem;
  color: #bdc3c7;
  margin-bottom: 20px;
}

.projectList {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.projectCard {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.projectCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.projectCard h4 {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 10px;
}

.detailsButton {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, transform 0.3s ease;
}

.detailsButton:hover {
  background: linear-gradient(90deg, #e52e71, #ff8a00);
  transform: scale(1.1);
}

.projectDetails {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  text-align: left;
}

.projectDetails h3 {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 15px;
}

.projectDetails p {
  font-size: 1.2rem;
  color: #bdc3c7;
  margin-bottom: 10px;
}

.projectDetails ul {
  list-style: none;
  padding: 0;
}

.projectDetails ul li {
  font-size: 1.1rem;
  color: #ffffff;
  margin-bottom: 5px;
}

.backButton {
  margin-top: 20px;
  background: #bdc3c7;
  color: #2c3e50;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, transform 0.3s ease;
}

.backButton:hover {
  background: #ecf0f1;
  transform: scale(1.05);
}
