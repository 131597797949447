.contentCreation {
  padding: 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(240, 240, 255, 0.9));
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: italic;  max-width: 600px;
  margin: auto;
}

h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 20px;
}

.restrictionMessage {
  text-align: center;
  font-size: 1rem;
  color: #e74c3c;
  background: rgba(231, 76, 60, 0.1);
  padding: 10px;
  border-radius: 8px;
}

/* Form Styles */
.uploadForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 0.9rem;
  font-weight: bold;
  color: #34495e;
  margin-bottom: 5px;
}

input, textarea, select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

input:focus, textarea:focus, select:focus {
  border-color: #3498db;
}

textarea {
  resize: none;
}

.formGroupCheckbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

a {
  color: #ff780a;
  text-decoration: none;
}

.uploadButton {
  padding: 10px 20px;
  font-size: 1rem;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.uploadButton:hover {
  background: #2a80b9;
}
