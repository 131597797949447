.teaching {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: linear-gradient(135deg, #f5f7fa, #ffffff);
  font-family: "Kanit", sans-serif;
  max-width: 1400px;
  margin-left: 60px;
}

.row {
  display: flex;
  gap: 10px;
}

.widget {
  flex: 1;
  padding: 10px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.widget h3 {
  font-size: 1rem;
  margin-bottom: 10px;
}

.timer {
  width: 80px;
  margin: 0 auto;
}

.timerText {
  font-size: 0.8rem;
}

.metrics {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.metricItem {
  text-align: center;
  font-size: 0.8rem;
}

.metricValue {
  font-size: 0.7rem;
  color: #6c5ce7;
}

.joinButton {
  padding: 5px 10px;
  background: #6c5ce7;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
}

.joinButton.disabled {
  background: #bdc3c7;
  cursor: not-allowed;
}

.graphWrapper {
  height: 200px;
  width: 100%;
}
