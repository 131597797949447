@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add this to index.css or a global CSS file */
.pulse-animation {
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  .parallax {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  img {
    max-width: 20%;
  }

  nav {
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .text-hero-sec {
    position: relative;
    left: 20%;
    top: 15px;
  }

  .mt-14 {
    margin-top: 0rem !important;
  }