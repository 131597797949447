/* Feedback.module.css */

.feedback {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin-left: 40px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}

.summary {
  text-align: center;
  margin-bottom: 30px;
}

.summary h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 10px;
}

.summary .stats {
  display: flex;
  justify-content: space-around;
  background: #ecf0f1;
  border-radius: 12px;
  padding: 10px 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.stats p {
  font-size: 1.1rem;
  color: #34495e;
  font-weight: 600;
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Suggestions Section */
.suggestions {
  flex: 1;
  min-width: 300px;
  padding: 20px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.suggestions h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 15px;
}

.suggestions ul {
  list-style: none;
  padding: 0;
}

.suggestion {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  font-size: 1.5rem;
  margin-right: 10px;
  color: #3498db;
}

.link {
  color: #3498db;
  font-weight: 600;
  text-decoration: none;
  transition: color 0.3s ease;
}

.link:hover {
  color: #1d6fa5;
}

/* Feedback Cards Section */
.feedbackCards {
  flex: 2;
  min-width: 400px;
  padding: 20px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feedbackCards h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 15px;
}

.card {
  background: #ecf0f1;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.cardHeader h4 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #34495e;
}

.cardHeader p {
  font-size: 1rem;
  color: #7f8c8d;
  font-weight: 600;
}

.cardBody {
  margin-top: 10px;
  font-size: 1rem;
  color: #34495e;
}

.cardBody p {
  margin: 5px 0;
}

.cardBody ul {
  list-style: disc;
  padding-left: 20px;
}

.cardBody li {
  margin-bottom: 5px;
}

.expanded {
  background: #ffffff;
  border: 1px solid #3498db;
  box-shadow: 0 4px 12px rgba(52, 152, 219, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .summary .stats {
    flex-direction: column;
    gap: 10px;
  }

  .feedbackCards,
  .suggestions {
    width: 100%;
  }
}
