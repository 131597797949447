.portfolioBuilding {
  padding: 20px;
  background: linear-gradient(to bottom, #1e293b, #0f172a);
  color: #ffffff;
  min-height: 100vh;
  max-width: 1400px;
  margin-left: 60px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}

h2 {
  font-size: 2.5rem;
  color: #f59e0b;
  font-weight: bold;
  text-align: center;
}

p {
  font-size: 1.2rem;
  color: #94a3b8;
  text-align: center;
  margin-bottom: 20px;
}

.portfolioEditor {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.sectionCard {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 15px;
  flex: 1 1 300px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.textArea {
  background: #1e293b;
  color: #ffffff;
  border: 1px solid #64748b;
  border-radius: 8px;
  padding: 10px;
  flex-grow: 1;
  resize: none;
  min-height: 80px;
}

.addButton {
  background: #3b82f6;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 auto;
  display: block;
}

.addButton:hover {
  background: #2563eb;
}

.preview {
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 12px;
  margin-top: 30px;
}

.previewSection {
  margin-bottom: 20px;
}

.previewSection h4 {
  font-size: 1.5rem;
  color: #f8fafc;
  margin-bottom: 10px;
}

.previewSection p {
  color: #cbd5e1;
  font-size: 1.1rem;
}
