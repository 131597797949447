.dashboard {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  background: linear-gradient(to bottom, #1f2024, #6b6c6e);
  color: #ffffff;
  min-height: 100vh;
  max-width: 1400px;
  margin-left: 60px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
}

/* Section Containers */
.feed, .notifications, .collaborators, .employability {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  border-radius: 16px;
  padding: 25px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

h3 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #00c6ff, #0072ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

/* Cards in Personalized Feed */
.cards {
  display: flex;
  gap: 20px;
}

.card {
  flex: 1;
  background: linear-gradient(135deg, #00c6ff, #0072ff);
  color: white;
  padding: 20px;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
}


.card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2), transparent 60%);
  transform: rotate(45deg);
  transition: opacity 0.5s ease;
}

.card:hover::before {
  opacity: 1;
}

.cardButton {
  background: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, transform 0.3s ease;
}

.cardButton:hover {
  background: rgba(255, 255, 255, 0.4);
}

/* Notifications List */
.notifications ul {
  list-style: none;
  padding: 0;
}

.notifications li {
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: white;
  font-weight: bold;
}

.notifications .update {
  background: linear-gradient(90deg, #4caf50, #81c784);
  box-shadow: 0 4px 15px rgba(76, 175, 80, 0.3);
}

.notifications .invite {
  background: linear-gradient(90deg, #e91e63, #f06292);
  box-shadow: 0 4px 15px rgba(233, 30, 99, 0.3);
}

.notifications .achievement {
  background: linear-gradient(90deg, #ff9800, #ffc107);
  box-shadow: 0 4px 15px rgba(255, 152, 0, 0.3);
}

.notifications li:hover {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

/* Collaborators Section */
.collaboratorCards {
  display: flex;
  gap: 20px;
}

.collaboratorCard {
  flex: 1;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.collaboratorCard:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
}

.collaboratorCard h4 {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 10px;
}

.collaboratorCard p {
  color: #b0bec5;
}

.cardButton {
  margin-top: 10px;
}

/* Employability Section */
.employability {
  text-align: center;
  background: linear-gradient(135deg, #1e88e5, #0d47a1);
  color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.ratingChart {
  width: 150px;
  margin: 0 auto;
  animation: pulse 2s infinite;
}

.ratingChart p {
  margin-top: 15px;
  font-size: 1.2rem;
  color: #ffffff;
}

/* Animations */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
