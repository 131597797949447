.learnPlatform {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 1400px;
}

/* Sidebar and Main Content Layout */
.layout {
  display: flex;
  flex: 1;
  height: calc(100vh - 60px); /* Subtract Navbar height */
}

.mainContent {
  flex: 1;
  padding: 20px;
  background: #f9f9f9;
  overflow-y: auto;
}
