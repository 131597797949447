/* General Layout */
.paidPlatform {
  padding: 40px;
  background: linear-gradient(to right, #1e293b, #3b4257);
  color: white;
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: normal;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
}

/* Hero Section */
.hero {
  text-align: center;
  margin-bottom: 50px;
}

.hero h1 {
  font-size: 3rem;
  background: linear-gradient(to right, #fbbf24, #facc15);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: normal;}

.hero p {
  font-size: 1.2rem;
  color: #d1d5db;
  margin-top: 10px;
}

/* Educator Grid */
.teacherGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

/* Educator Card */
.teacherCard {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.teacherCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.5);
}

.profileImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.cardContent {
  padding: 20px;
  text-align: center;
}

.cardContent h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.cardContent .level {
  font-size: 1rem;
  color: #93c5fd;
  margin-bottom: 5px;
}

.cardContent .price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #22c55e;
}

.bookButton {
  padding: 10px 20px;
  background: #facc15;
  color: #1e293b;
  border-radius: 8px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.bookButton:hover {
  background: #fbbf24;
  transform: scale(1.1);
}

/* Overlay on Hover */
.cardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(100%);
  transition: transform 0.4s ease;
}

.teacherCard:hover .cardOverlay {
  transform: translateY(0);
}

.cardOverlay h4 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.cardOverlay ul {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
}

.cardOverlay ul li {
  font-size: 1rem;
}

.cardOverlay blockquote {
  font-size: 1rem;
  font-style: italic;
  color: #93c5fd;
}
