.illustrationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  height: 300px;
  max-width: 800px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.glowingCircle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: linear-gradient(135deg, #007bff, #6610f2, #00d4ff);
  box-shadow: 0 0 20px rgba(0, 123, 255, 0.5), 0 0 40px rgba(102, 16, 242, 0.5);
  animation: glowPulse 3s infinite ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 60px;
  height: 60px;
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-top: 6px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.text {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 20px;
  color: #2c3e50;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation: float 3s ease-in-out infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes glowPulse {
  0% {
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5), 0 0 20px rgba(102, 16, 242, 0.5);
  }
  50% {
    box-shadow: 0 0 30px rgba(0, 123, 255, 0.8), 0 0 60px rgba(102, 16, 242, 0.8);
  }
  100% {
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5), 0 0 20px rgba(102, 16, 242, 0.5);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
